import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let ColorPickerComponent = class ColorPickerComponent extends Vue {
    constructor() {
        super(...arguments);
        this.showMenu = false;
    }
    get computedValue() {
        return this.value;
    }
    set computedValue(newValue) {
        this.$emit('input', newValue);
    }
    handleColorPickerInput(event) {
        // Manipule a seleção de cores aqui, se necessário
        this.$emit('input', event);
        this.computedValue = '' || event.hex; // Use a propriedade 'hex' para definir como uma string vazia
    }
};
__decorate([
    Prop({ default: '' })
], ColorPickerComponent.prototype, "label", void 0);
__decorate([
    Prop()
], ColorPickerComponent.prototype, "value", void 0);
ColorPickerComponent = __decorate([
    Component
], ColorPickerComponent);
export default ColorPickerComponent;
