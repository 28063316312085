import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let FormDivulgacao = class FormDivulgacao extends Vue {
    constructor() {
        super(...arguments);
        this.showForm = true;
        this.dialogPoliticaPrivacidade = false;
        this.existsPhone = false;
    }
    get parceria() {
        if (this.propParceria.campanha_id) {
            return this.propParceria.campanha;
        }
        else {
            return this.propParceria;
        }
    }
    showPoliticaPrivacidade() {
        this.dialogPoliticaPrivacidade = true;
    }
    async onSubscribe() {
        this.showForm = false;
    }
};
__decorate([
    Prop()
], FormDivulgacao.prototype, "propParceria", void 0);
FormDivulgacao = __decorate([
    Component
], FormDivulgacao);
export default FormDivulgacao;
