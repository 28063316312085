import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { parceriaStore, userActionStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import ParceriaAcaoCrudComponent from '@/components/ParceriaAcaoCrudComponent.vue';
import ParceriaCupomCrudComponent from '@/components/ParceriaCupomCrudComponent.vue';
import EnderecoComponent from '@/components/EnderecoComponent.vue';
import FormParcerias from './FormParcerias.vue';
import _ from 'lodash';
import ColorPickerComponent from '../../../components/ColorPickerComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newParceria } from '@/interfaces/parceria';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.tab = 'tab_dados_cadastrais';
        this.entityObject = null;
        this.search = '';
        this.selectedItem = {};
        this.data_inicio = null;
        this.data_termino = null;
        this.statusSelected = [];
        this.acoesSelected = [];
        this.parceriaAcoes = [];
        this.nichoSelected = [];
        this.parceriaAcaoHeaders = [
            { text: 'Ação', value: 'acao_id' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Data/Hora', value: '' },
            { text: 'Executores', value: '' },
        ];
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    get parceriasNicho() {
        if (this.id) {
            if (this.entityObject.nicho_id === 6 || this.entityObject.nicho_id === 7) {
                return this.parceriaNichoOptions().map((item) => {
                    return { ...item, readOnly: true };
                });
            }
        }
        return this.parceriaNichoOptions();
    }
    baixarQrcode() {
        // @ts-ignore
        const qrcodeData = this.$refs.qrcode.$el.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = qrcodeData;
        link.download = 'qrcode.png';
        link.click();
    }
    async flyer() {
        const res = await parceriaStore.getParceriaFlyer(this.entityObject.id);
        if (res.data) {
            if (res.headers['content-type'].includes('html')) {
                const html = res.data;
                const w = window.open();
                // @ts-ignore
                w.document.write(html);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
            else {
                const uri = 'data:image/png;base64,' + res.data;
                const a = document.createElement('a');
                a.href = uri;
                a.download = 'flyer.jpg';
                a.click();
            }
        }
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.entityObject.data_inicio = this.data_inicio + 'T04:00:00.000Z';
        this.entityObject.data_termino = this.data_termino + 'T04:00:00.000Z';
        if (this.nichoSelected.length > 0) {
            this.entityObject.nicho_id = this.nichoSelected[1].id;
        }
        if (this.statusSelected.length > 0) {
            this.entityObject.status_id = this.statusSelected[1].id;
        }
        // @ts-ignore
        if (this.$refs.enderecoComponent.cep) {
            // @ts-ignore
            this.entityObject.cep = this.$refs.enderecoComponent.cep;
        }
        // @ts-ignore
        if (this.$refs.enderecoComponent.bairro) {
            // @ts-ignore
            this.entityObject.bairro = this.$refs.enderecoComponent.bairro;
        }
        // @ts-ignore
        if (this.$refs.enderecoComponent.rua) {
            // @ts-ignore
            this.entityObject.rua = this.$refs.enderecoComponent.rua;
        }
        // @ts-ignore
        if (this.$refs.enderecoComponent.numero) {
            // @ts-ignore
            this.entityObject.numero = this.$refs.enderecoComponent.numero;
        }
        // @ts-ignore
        if (this.$refs.enderecoComponent.latitudeLongitude) {
            // @ts-ignore
            this.entityObject.latitude_longitude =
                // @ts-ignore
                this.$refs.enderecoComponent.latitudeLongitude;
        }
        if (this.id) {
            const result = await parceriaStore.updateParceria(this.entityObject);
        }
        else {
            const result = await parceriaStore.createParceria(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
    }
    get estilo() {
        const est = {
            'border-radius': '0px',
            'background': this.entityObject.landing_page_background_style,
        };
        if (!this.entityObject.landing_page_dobra_titulo ||
            !this.entityObject.landing_page_dobra_texto) {
            // @ts-ignore
            est.height = '90%';
        }
        return est;
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    async landingPageRestaurarPadrao() {
        const padrao = await parceriaStore.getParceriaRestaurarPadrao(this.id);
        this.entityObject = Object.assign(this.entityObject, padrao);
    }
    async mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.entityObject = await parceriaStore.getParceria(this.id);
            if (this.entityObject.data_inicio) {
                this.data_inicio = this.entityObject.data_inicio.substr(0, 10);
            }
            if (this.entityObject.data_termino) {
                this.data_termino = this.entityObject.data_termino.substr(0, 10);
            }
            setTimeout(() => {
                // @ts-ignore
                this.$refs.enderecoComponent.cep = this.entityObject.cep;
                // @ts-ignore
                this.$refs.enderecoComponent.numero = this.entityObject.numero;
                // @ts-ignore
                this.$refs.enderecoComponent.rua = this.entityObject.rua;
                // @ts-ignore
                this.$refs.enderecoComponent.bairro = this.entityObject.bairro;
                // @ts-ignore
                this.$refs.enderecoComponent.latitudeLongitude =
                    this.entityObject.latitude_longitude;
            }, 100);
            if (this.entityObject.nicho_id) {
                this.nichoSelected = [
                    _.find(
                    // @ts-ignore
                    this.parceriaNichoOptions(), (i) => i.id == this.entityObject.nicho_id).name,
                    _.find(
                    // @ts-ignore
                    this.parceriaNichoOptions(), (i) => i.id == this.entityObject.nicho_id),
                ];
            }
            if (this.entityObject.status_id) {
                this.statusSelected = [
                    _.find(
                    // @ts-ignore
                    this.parceriaStatusOptions(), (i) => i.id == this.entityObject.status_id).name,
                    _.find(
                    // @ts-ignore
                    this.parceriaStatusOptions(), (i) => i.id == this.entityObject.status_id),
                ];
            }
        }
        else {
            this.entityObject = newParceria();
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
            OcorrenciaComponent,
            DateFieldComponent,
            LogShowComponent,
            ClickableCard,
            ParceriaAcaoCrudComponent,
            ParceriaCupomCrudComponent,
            EnderecoComponent,
            ColorPickerComponent,
            FormParcerias,
        },
    })
], Create);
export default Create;
